import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-patient-bills-profile',
  templateUrl: './patient-bills-profile.component.html',
  styleUrls: ['../patient-profile/patient-profile.component.scss', './patient-bills-profile.component.scss']
})
export class PatientBillsProfileComponent implements OnInit {

  id:any = 0;
  ptype:any='';
  patientBills:any = [];
  constructor(private router:Router, private route: ActivatedRoute, private dataService: DataService) { }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    this.loadPatientBills();
  }

  loadPatientBills(){
	  this.dataService.getPatientBills(this.id, this.ptype).subscribe( (data: any )=> {
				if(data.status == 0){
					this.patientBills = data.bills;
          console.log("bills>>>", this.patientBills);
				}
				
		});
	}
	
	filterPatientBills(ptype){
		this.ptype = ptype;
		this.loadPatientBills();
	}

}
