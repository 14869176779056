import { Component, Input, OnInit } from '@angular/core';
import { DataService } from '../../../data.service';

@Component({
  selector: 'app-goal-tracker',
  templateUrl: './goal-tracker.component.html',
  styleUrls: ['../../patient-profile/patient-profile.component.scss', './goal-tracker.component.scss']
})
export class GoalTrackerComponent implements OnInit {

  @Input('id') id:any;
  @Input('mode') mode:any;
  goals = [];
  limit:any = 0;
  selectedAssessment:any = {id:0, title:""}
  selectedGoal:any = {id:0};
  selectedSubgoal:any = {id:0};
  selectedTask:any = {id:0, task_title:""}
  activities:any = {};
  selectedEv:any = {};
  evVal:any = 0;
  evPrompt:any = {status:0, level:0};
  evNotes:any = "";
  evalHistory:any = {status:0, activity:"", trialdata:[]}
  departments:any = {departments:[], mydept:"", mydeptId:0}
  selectedDept:any = {id:0, name:"All departments"}

  constructor(private dataService: DataService) { }

  ngOnInit() {
    
    if(this.mode == 2){
      this.limit = 3;
    }

    this.loadGoals();
    this.loadDepartments();
  }

  loadGoals(assessment_id = this.selectedAssessment.id){
    this.dataService.getAssessmentGoalsActivities(this.id, assessment_id, this.limit, this.selectedDept.id).subscribe( (data:any = {goals:[]} )=> {
  		this.goals = data.goals;
      
      
    });
  }

  loadDepartments(){
    this.dataService.getDepts2().subscribe( (data:any )=> {
  		this.departments = data;
    });
  }

  selectGoal(goal){
    this.selectedGoal = goal;
  }
  selectSubgoal(sg){
    this.selectedSubgoal = sg;
    
  }
  selectTask(task){
    this.selectedTask = task;
  }

  loadActivities(goal_id) {
  	this.dataService.getGaActivities(goal_id, this.id).subscribe( (data: any[] )=> {
  		
      	this.activities = data;
      
    });
  }

  loadGoalEv(goal){
    this.dataService.getGoalEv(goal, this.id).subscribe( (data: any[] )=> {
      this.selectedEv = data;
      
    });   
  }

  setEvVal(val){
    this.evVal = val;
  }

  setPrompt(param, val){
    
    if(param == 'status'){
      if(val == 'on') {
        val = 1;
      } else {
        val = 0;
      }
    }

    this.evPrompt[param] = val;
    

  }

  markTrial(){
    let data = {
      patient_id:this.id,
      assignment_id:this.selectedGoal.id,
      task_id: this.selectedTask.id,
      score: this.evVal,
      evPrompt: this.evPrompt,
      notes:this.evNotes
    };

    
    this.dataService.postTaskEvaluation(data).subscribe( (res:any) => {
      if (res.status == 0) {
        this.selectedTask.progress = res.progress;
        this.selectedTask.trial_count = res.trial_count;
        this.evPrompt = {status:0, level:0};
        this.selectedTask = {id:0, task_title:""};
        this.evNotes = "";
      } else {
        alert(res.msg);
      }
    }); 

  } 

  closeEv(){
    this.selectedTask = {id:0, task_title:""};
  }

  closeGoalEv(){
    this.selectedGoal = {id:0};
  }

  closeSgEv(){
    this.selectedSubgoal = {id:0};
  }

  markGoalTrial(){
    let data = {
      patient_id:this.id,
      assignment_id:this.selectedGoal.id,
      score: this.evVal,
      evPrompt: this.evPrompt,
      notes:this.evNotes
    };

    
    this.dataService.postGoalEvaluation(data).subscribe( (res:any) => {
      if (res.status == 0) {
        this.selectedGoal.trials.progress = res.progress;
        this.selectedGoal.trials.count = res.trial_count;
        this.evPrompt = {status:0, level:0};
        this.selectedGoal = {id:0};
        this.evNotes = "";
      } else {
        alert(res.msg);
      }
    }); 

  }
  
  markSgTrial(){
    let data = {
      patient_id:this.id,
      assignment_id:this.selectedGoal.id,
      subgoal_id:this.selectedSubgoal.id,
      score: this.evVal,
      evPrompt: this.evPrompt,
      notes:this.evNotes
    };

    
    this.dataService.postSubgoalEvaluation(data).subscribe( (res:any) => {
      if (res.status == 0) {
        this.selectedSubgoal.progress = res.progress;
        this.selectedSubgoal.trial_count = res.trial_count;
        this.evPrompt = {status:0, level:0};
        this.selectedSubgoal = {id:0};
        this.evNotes = "";
      } else {
        alert(res.msg);
      }
    }); 

  }

  markGoalDone(goal){
    var cnf = confirm("Do you really want to mark this goal as completed?");

    if(cnf){

      let data = {
        patient_id:this.id,
        goal_assignment_id:goal.id
        
      }
  
      this.dataService.postMarkGoalCompletion(data).subscribe( (res:any) => {
        if (res.status == 0) {
          goal.is_completed = 1;
        } else {
          alert(res.msg);
        }
      }); 
    }
  }

  markSubgoalDone(sg){
    var cnf = confirm("Do you really want to mark this subgoal as completed?");

    if(cnf){

      let data = {
        patient_id:this.id,
        goal_assignment_id:this.selectedGoal.id,
        subgoal_id:sg.id
        
      }
  
      this.dataService.postMarkSubgoalCompletion(data).subscribe( (res:any) => {
        if (res.status == 0) {
          sg.is_completed = 1;
        } else {
          alert(res.msg);
        }
      }); 
    }
  }

  markTaskDone(task){

    var cnf = confirm("Do you really want to mark this activity as completed?");

    if(cnf){

      let data = {
        patient_id:this.id,
        goal_assignment_id:this.selectedGoal.id,
        task_id:task.id
      }
  
      this.dataService.postMarkTaskCompletion(data).subscribe( (res:any) => {
        if (res.status == 0) {
          task.is_completed = 1;
        } else {
          alert(res.msg);
        }
      }); 
    }

    

  }

  trialHistory(task){
    this.evalHistory = {status:0, activity:"", trialdata:[]};
    this.dataService.getTaskEv(task.id, this.selectedGoal.id, this.id).subscribe( (data: any )=> {
  		if(data.status == 0){
        this.evalHistory = data;
        $("#modalEvalHistory").modal("show");
      } else {
        alert(data.msg);
      }
    });
  }

  goalTrialHistory(goal){
    this.evalHistory = {status:0, activity:"", trialdata:[]};
    this.dataService.getGoalEv(goal.id, this.id).subscribe( (data: any )=> {
  		if(data.status == 0){
        this.evalHistory = data;
        $("#modalEvalHistory").modal("show");
      } else {
        alert(data.msg);
      }
    });
  }

  subgoalTrialHistory(sg){
    this.evalHistory = {status:0, activity:"", trialdata:[]};
    this.dataService.getSubgoalEv(this.selectedGoal.id, sg.id, this.id).subscribe( (data: any )=> {
  		if(data.status == 0){
        this.evalHistory = data;
        $("#modalEvalHistory").modal("show");
      } else {
        alert(data.msg);
      }
  });
  }

  selectDept(dept){
    this.selectedDept = dept;
    this.loadGoals();
  }

}
