import { Component, Input, OnInit } from '@angular/core';
import { DataService } from '../../../data.service';

@Component({
  selector: 'app-patientoverview',
  templateUrl: './patientoverview.component.html',
  styleUrls: ['../patient-profile.component.scss','./patientoverview.component.scss']
})
export class PatientoverviewComponent implements OnInit {

  @Input('id') id:any;
  data:any = {status:0, activities:[]};
  alerts:any = {list:[], current_user:0}
  dept:any = "";
  consultant:any = "";
  limit:any = 10;
  selectedRemark:any = {
    category:"", 
    title:"", 
    consultant:"", 
    date:"",
    dept:"",
    tag:"",
    item:{
      id:0,
      title:"",
      contents:"",
      
    }
  };

  constructor(private dataService: DataService) { }

  ngOnInit() {
    this.loadOverview();
    this.loadAlerts();
  }

  loadOverview(){
    this.dataService.getClientOverview(this.id, this.dept, this.consultant, this.limit ).subscribe( (data: any[] )=> {
      this.data = data;
      
    });
  }

  loadAlerts(){
    this.dataService.getPatientAlerts(this.id).subscribe( (data: any[] )=> {
      this.alerts = data;
      
    });
  }


  submitNotes(data){
    data.patient_id = this.id;
    //data.date = $('#note-date').val();
    $('#btn-remarks').attr('disabled', 'true');
    $('#btn-remarks').text('Submitting');
    
    this.dataService.postNotes(data).subscribe( (res:any) => {
      if(res.status == 0) {
        alert(res.msg);
        $('#modalRemarks').modal('hide');
        $('.btn-cancel').click();
        $('#btn-remarks').text('Submit');
        this.loadOverview();
      } else {
        alert(res.msg);
      }


    });

    
    
  }

  loadRemarks(item){
    this.selectedRemark = item;
    $("#modalViewRemarks").modal("show");
    console.log(item);
  }

  newAlert(){
    let input = prompt("New Alert");

    if(input){
      if(input.length > 0){
        let data = {
          patient_id:this.id,
          content:input
        }

        this.dataService.postPatientAlert(data).subscribe( (res:any) => {
          if(res.status == 0) {
            this.loadAlerts();
          } else {
            alert(res.msg);
          }
        });

      }
    }

  }

  editAlert(alert){
    let input = prompt("Edit Alert", alert.content);

    if(input){
      if(input.length > 0){
        let data = {
          id:alert.id,
          content:input
        }

        this.dataService.postEditPatientAlert(data).subscribe( (res:any) => {
          if(res.status == 0) {
            this.loadAlerts();
          } else {
            alert(res.msg);
          }
        });

      }
    }

  }

  deleteAlert(alert){
    var cnf = confirm("Do you really want to delete this alert?");

    if(cnf){
      let data = {id:alert.id}
      this.dataService.postDeletePatientAlert(data).subscribe( (res:any) => {
        if(res.status == 0) {
          this.loadAlerts();
        } else {
          alert(res.msg);
        }
      });
    }
  }

}
