import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-asesmntt-activity',
  templateUrl: './asesmntt-activity.component.html',
  styleUrls: ['../patient-profile/patient-profile.component.scss','./asesmntt-activity.component.scss']
})
export class AsesmnttActivityComponent implements OnInit {

  id = '0';

  goals = {goals:[]};
  departments:any = {status:0, departments:[], mydept:"", mydeptId:0}
  levels = [];
  categories = [];
  selectedLevel = {"id":0, "level":""};
  selectedCategory = {"id":0, "level":""};
  selectedGoal = {"id":0};
  selectedActivity:any = {};
  selectedTask:any = {id:0};
  subGoals = [];
  steps = [];
  selectedStep = {};
  tutorials = [];
  video = {};
  noVideoText = "Loading video";
  requirements = [];
  stats = [];
  
  libraryMode = 1; // 1- in-clinic, 2- homeprogram;
  selectedGoals:any = [];
  selectedGoalDept:any = 0;
  

  constructor(private router:Router, private route: ActivatedRoute, private dataService: DataService) {
    this.router.routeReuseStrategy.shouldReuseRoute = function() {
      return false;
    };
  }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');

    this.loadCategories();
  	this.loadLevels();
  	this.loadGoals();
    this.loadDepartments();
    
    
  }

  loadDepartments(){
    this.dataService.getDepts2().subscribe( (data:any )=> {
      this.departments = data
      if(data.mydeptId != 0){
        this.selectDept({id:data.mydeptId, name:data.mydept});
      }
      
    } );
  }

  selectDept(dept){
    this.selectedGoalDept = dept.id;
  }

  loadGoals() {

    

    if(this.libraryMode == 1){

      this.dataService.getGaLibraryGoals(this.selectedLevel.id, this.selectedCategory.id).subscribe( (data:any = {status:0, goals:[]} )=> {
	  		this.goals = data;
        if(data.goals.length > 0){
          this.selectGoal(data.goals[0]);
        }
	  		
	    } );

    } else {
      //homeprogram
      /*this.dataService.getGoals(this.selectedLevel.id, this.selectedCategory.id).subscribe( (data: any[] )=> {
	  		this.goals = data;
	  		
	    } );*/
    }

  	
  }

  loadCategories() {

    if(this.libraryMode == 1){
      this.dataService.getGaCategories().subscribe( (data: any[] )=> {
	  		this.categories = data;
	  		//@ts-ignore
	  		if (data.categories.length > 0) {
	  			//@ts-ignore
	  			this.selectedCategory = data.categories[0];

	  		}
	  		
	    } );
    } else {
      this.dataService.getGoalCategories().subscribe( (data: any[] )=> {
	  		this.categories = data;
	  		//@ts-ignore
	  		if (data.categories.length > 0) {
	  			//@ts-ignore
	  			this.selectedCategory = data.categories[0];

	  		}
	  		
	    } );
    }

  	
  }

  loadLevels() {

    if(this.libraryMode == 1) {
      this.dataService.getGaLevels().subscribe( (data: any[] )=> {
	  		this.levels = data;
	  		//@ts-ignore
	  		if (data.levels.length > 0) {
	  			//@ts-ignore
	  			this.selectedLevel = data.levels[0];
	  			
	  		}
	  		
	    } );
    } else {
      this.dataService.getGoalLevels().subscribe( (data: any[] )=> {
	  		this.levels = data;
	  		//@ts-ignore
	  		if (data.levels.length > 0) {
	  			//@ts-ignore
	  			this.selectedLevel = data.levels[0];
	  			
	  		}
	  		
	    } );  
    }

  	
  }

  selectGoal(goal) {

  	this.selectedGoal = goal;
  	this.subGoals = [];
    //load subGoals

    if(this.libraryMode == 1){
      this.dataService.getGaSubGoals(goal.id).subscribe( (data: any[] )=> {
	  		this.subGoals = data;
	  		console.log('subgoals', data);
	    } );
    } else {
      this.dataService.getSubGoals(goal.id).subscribe( (data: any[] )=> {
	  		this.subGoals = data;
	  		console.log('subgoals', data);
	    } );
    }

    //Load Steps
    //this.loadSteps(goal.id);
    //load tutorials
    this.loadTutorials(goal.id);

  }

  


  selectLevel(index) {
  	//@ts-ignore
  	this.selectedLevel = this.levels.levels[index];
  	this.loadGoals();
  }

  selectCategory(index) {
  	//@ts-ignore
  	this.selectedCategory = this.categories.categories[index];
  	this.loadGoals();
  }

  loadSteps(goal_id) {
    this.dataService.getGoalSteps(goal_id).subscribe( (data: any[] )=> {
        this.steps = data;      
        console.log('steps', this.steps);
        
    } );
  }

  loadRequirements(goal_id) {
    this.dataService.getRequirements(goal_id).subscribe( (data: any[] )=> {
        this.requirements = data;        
        //console.log(this.requirements);
    } );
  }

  loadTutorials(goal_id) {
    /*this.dataService.getTutorials(goal_id).subscribe( (data: any[] )=> {
      this.tutorials = data;
      console.log('tuts', this.tutorials);
      
    });*/
  }

  selectActivity(activity){
    this.selectedActivity = activity;
  }



  addLevel() {
    var cnf = confirm("Do you really want to add a new level?");

    if (cnf) {

      if(this.libraryMode == 1){
        this.dataService.postAddGaLevel().subscribe( (res:any) => {
        
          if(res.status == 0) {
                alert(res.msg);
                this.loadLevels();
              } else {
                alert(res.msg);
              }
        });
      } else {
        this.dataService.postAddGoalLevel().subscribe( (res:any) => {
        
          if(res.status == 0) {
                alert(res.msg);
                this.loadLevels();
              } else {
                alert(res.msg);
              }
        });
      }

      
    }

  }

  submitCategory(data) {
    
    $('#btn-add-category').attr('disabled', 'true');

    if(this.libraryMode == 1){
      this.dataService.postAddGaCategory(data).subscribe( (res:any) => {
        $('#btn-add-category').removeAttr('disabled');
          if(res.status == 0) {
              $('#modal-add-category').modal('hide');
              $('.btn-close-modal').trigger('click');
              this.loadCategories();
              alert(res.msg);
              
            } else {
              alert(res.msg);
            }
      });
    } else {
      this.dataService.postAddGoalCategory(data).subscribe( (res:any) => {
        $('#btn-add-category').removeAttr('disabled');
          if(res.status == 0) {
              $('#modal-add-category').modal('hide');
              $('.btn-close-modal').trigger('click');
              this.loadCategories();
              alert(res.msg);
              
            } else {
              alert(res.msg);
            }
      });
    }

    

  }

  

  submitGoal(data) {
    data.level_id = this.selectedLevel.id;
    data.category_id = this.selectedCategory.id;
    $('#btn-add-goal').attr('disabled', 'true');

    if(this.libraryMode == 1){
      this.dataService.postAddGa(data).subscribe( (res:any) => {
        $('#btn-add-goal').removeAttr('disabled');
          if(res.status == 0) {
              $('#modal-add-goal').modal('hide');
              $('.btn-cancel-goal').click();
              this.loadGoals();
              
              
            } else {
              alert(res.msg);
            }
      });
    } else {
      this.dataService.postAddGoalLib(data).subscribe( (res:any) => {
        $('#btn-add-goal').removeAttr('disabled');
          if(res.status == 0) {
              $('#modal-add-goal').modal('hide');
              $('.btn-cancel-goal').click();
              this.loadGoals();
              
              
            } else {
              alert(res.msg);
            }
      });
    }

    

  }

  deleteGoal(goal) {

    var cnf = confirm("Are you sure you want to delete this goal?");
    var data = {"goal_id":goal.id  };

    if(this.libraryMode == 1){
      this.dataService.postDeleteGaGoal(data).subscribe( (res:any) => {
        if(res.status == 0) {
              this.loadGoals();
              
            } else {
              alert(res.msg);
            }
      });
    } else {
      this.dataService.postDeleteGoalLib(data).subscribe( (res:any) => {
        if(res.status == 0) {
              this.loadGoals();
              
            } else {
              alert(res.msg);
            }
      });
    }

    

  }

  editGoal(data) {
    $('#btn-edit-goal').attr('disabled', 'true');
    //@ts-ignore
    data.goal_id = this.selectedGoal.id;

    if(this.libraryMode == 1){
      this.dataService.postEditGaGoal(data).subscribe( (res:any) => {
        $('#btn-edit-goal').removeAttr('disabled');
        if(res.status == 0) {
              this.loadGoals();
              $('#modal-edit-goal').modal('hide');
              $('.btn-cancel-egoal').click();
            } else {
              alert(res.msg);
            }
      });
    } else {
      this.dataService.postEditGoalLib(data).subscribe( (res:any) => {
        $('#btn-edit-goal').removeAttr('disabled');
        if(res.status == 0) {
              this.loadGoals();
              $('#modal-edit-goal').modal('hide');
              $('.btn-cancel-egoal').click();
            } else {
              alert(res.msg);
            }
      });
    }

    

  }

  submitSub(data) {
    $('#btn-add-subgoal').attr('disabled', 'true');
    //@ts-ignore
    data.goal_id = this.selectedGoal.id;

    if(this.libraryMode == 1){
      this.dataService.postAddGaActivity(data).subscribe( (res:any) => {
        $('#btn-add-subgoal').removeAttr('disabled');
          if(res.status == 0) {
              $('#modal-add-subgoal').modal('hide');
              $('.btn-cancel-subgoal').click();
              //@ts-ignore
              this.dataService.getGaSubGoals(this.selectedGoal.id).subscribe( (data: any[] )=> {
                  this.subGoals = data;
                  
              } );
              
              
            } else {
              alert(res.msg);
            }
      });
    } else {
      this.dataService.postAddActivity(data).subscribe( (res:any) => {
        $('#btn-add-subgoal').removeAttr('disabled');
          if(res.status == 0) {
              $('#modal-add-subgoal').modal('hide');
              $('.btn-cancel-subgoal').click();
              //@ts-ignore
              this.dataService.getSubGoals(this.selectedGoal.id).subscribe( (data: any[] )=> {
                  this.subGoals = data;
                  
              } );
              
              
            } else {
              alert(res.msg);
            }
      });
    }

    

  }

  addNewTask(activity){
    this.selectedActivity = activity;
    $('#modal-add-task').modal();
  }

  submitTask(data) {
    //@ts-ignore
    data.activity_id = this.selectedActivity.activity_id;
    data.task_trials = 1;
    $('#btn-add-task').attr('disabled', 'true');

    if(this.libraryMode == 1) {
      this.dataService.postAddGaTask(data).subscribe( (res:any) => {
        $('#btn-add-task').removeAttr('disabled');
          if(res.status == 0) {
              $('#modal-add-task').modal('hide');
              $('.btn-cancel-task ').click();
              this.dataService.getGaSubGoals(this.selectedGoal.id).subscribe( (data: any[] )=> {
                  this.subGoals = data;
                  
              } );
              
              
            } else {
              alert(res.msg);
            }
      });
    } else {
      this.dataService.postAddGoalTask(data).subscribe( (res:any) => {
        $('#btn-add-task').removeAttr('disabled');
          if(res.status == 0) {
              $('#modal-add-task').modal('hide');
              $('.btn-close-modal').trigger('click');
              this.dataService.getSubGoals(this.selectedGoal.id).subscribe( (data: any[] )=> {
                  this.subGoals = data;
                  
              } );
              
              
            } else {
              alert(res.msg);
            }
      });
    }

    

  }

  submitStep(data) {
    $('#btn-add-step').attr('disabled', 'true');
    //@ts-ignore
    data.activity_id = this.selectedTask.id;

    this.dataService.postAddGoalActivityStep(data).subscribe( (res:any) => {
      $('#btn-add-step').removeAttr('disabled');
        if(res.status == 0) {
            $('#modal-add-step').modal('hide');
            $('.btn-cancel-step').click();
            this.loadActivitySteps(this.selectedTask.id);
            
            
          } else {
            alert(res.msg);
          }
    });

  }

  selectStep(step){
    this.selectedStep = step;

  }

  
  submitMaterial(data) {
    $('#btn-add-material').attr('disabled', 'true');
    //@ts-ignore
    data.step_id = this.selectedStep.id;

    this.dataService.postAddGoalActivityMaterial(data).subscribe( (res:any) => {
      $('#btn-add-material').removeAttr('disabled');
        if(res.status == 0) {
            $('#modal-add-material').modal('hide');
            $('.btn-close-modal').click();
            this.loadActivitySteps(this.selectedTask.id);
            
            
          } else {
            alert(res.msg);
          }
    });

  }

  deleteStep(step_id) {

    var cnf = confirm("Are you sure you want to delete this step?");
    var data = {"id":step_id};

    this.dataService.postDeleteGoalActivityStep(data).subscribe( (res:any) => {
      
        if(res.status == 0) {
            this.loadActivitySteps(this.selectedTask.id);
            
          } else {
            alert(res.msg);
          }
    });

  }

  toggleGoalSelection(goal_id){
    
    if(this.selectedGoals.includes(goal_id)){
      this.selectedGoals.splice( this.selectedGoals.indexOf(goal_id) );
    } else {
      this.selectedGoals.push(goal_id);
    }

    console.log(this.selectedGoals);

  }

  submitAssignGoals(sendToPhone=false){

    if(this.selectedGoals.length > 0){
      let data = {
        selected_goals:this.selectedGoals,
        patient_id:this.id,
        sendToPhone: sendToPhone
      }
      
  
      this.dataService.postGaAssignGoalsWithId(data).subscribe( (res:any) => {
        $('#btn-assign-goals').removeAttr('disabled');
        $('#btn-assign-goals').text('Assign');
        if (res.status == 0) {
          
          this.router.navigate(['/dashboard/client-goals/'+this.id]);
  
        } else {
          alert(res.msg);
        }
  
  
      });
    } else {
      alert("Select atleast one goal");
    }

    

  }

  selectTask(task){
    this.selectedTask = task;

    //Load steps
    this.loadActivitySteps(task.id);
  }

  loadActivitySteps(activity_id){
    this.dataService.getActivitySteps(activity_id).subscribe( (data: any[] )=> {
      this.steps = data;
    } );
  }

  
  

}
