import { Component, Input, OnInit } from '@angular/core';
import { DataService } from '../../data.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['../../dashboard/patient-profile/patient-profile.component.scss', './info.component.scss']
})
export class InfoComponent implements OnInit {

  @Input('id') id:any;
  client:any = {name:"", reg_no:"", address:"", phone:"", email:"", last_visit:"", age:"", dob:"", gender:"", class_std:"", allow_merge: false}
  ogPatientId:any = 0;
  merging:any = false;
  constructor(private dataService: DataService, private router:Router) { }

  ngOnInit() {
    this.loadClient();

    let context = this;

    $('body').on('focus', '#inputPatient', function(){
      let moi = $(this);
      let branch = (localStorage.getItem("branch") !== null ) ? JSON.parse(localStorage.getItem("branch")) : {id:0, name:""};
      //@ts-ignore
      moi.autocomplete({
        appendTo: '#modal-merge',
        source: function(request, response) {
            $.ajax({
              url:context.dataService.REST_API_SERVER + 'patient/autocomplete',
              type:'GET',
              dataType:'json',
              data: {term: request.term, branch:branch.id},
              headers: {key:localStorage.getItem('key')},
              success: function(responseData) {
                
                var array = responseData.map(function(element){
                  return {value: element['value'] , op_number: element['op_number'], patient_id: element['patient_id'], patient_name: element['patient_name'], phone: element['phone']};
                });
                response(array);
    
              }
            })
          }, 
          select: function(event, ui) {
              moi.val(ui.item.patient_name);
              context.ogPatientId = ui.item.patient_id;
              /*context.ngZone.run(() => {
                context.router.navigate(['/dashboard/nursing/generate-bill/'+ui.item.patient_id]);
              });*/
              
              return false;
          }
      });
    });
  }

  loadClient(){
    this.dataService.getClientProfile(this.id).subscribe( (data: any[] )=> {
      this.client = data;
      
    });
  }

  loadEdPatient(){
    this.dataService.getPatientProfile(this.id).subscribe( (data:any )=> {
      this.dataService.edPatient = data;
      
    });
  }

  deletePatient(patient){
    let cnf = confirm("Do you really want to delete " + patient.name + "? You can't undo this later.");
  
    if(cnf){
      let data = {id:patient.id}
      this.dataService.postDeletePatient(data).subscribe( (res: any) => {
        if(res.status == 0){
          this.router.navigate(['/dashboard/patients/branch']);
          
        } else {
          alert(res.msg);
        }
  
      });
    }
  
  }

  toggleInternational(val){

    let cnfText = "Do you want to mark this patient ";

    if(val == 1){
      cnfText += "International?";
    } else {
      cnfText += "Indian?";
    }

    let cnf = confirm(cnfText);

    if(cnf){
      this.client.is_international = val;

      let data = {
        patient_id:this.id,
        value:val
      }

      this.dataService.postUpdatePatientInternational(data).subscribe( (res: any) => {
        if(res.status == 0){
          //
        } else {
          alert(res.msg);
        }
  
      });

    }
  }

  toggleVSSC(val){

    let cnfText = "Do you want to mark this patient ";

    if(val == 1){
      cnfText += "VSSC?";
    } else {
      cnfText += "not VSSC?";
    }

    let cnf = confirm(cnfText);

    if(cnf){
      this.client.is_vssc = val;

      let data = {
        patient_id:this.id,
        value:val
      }

      this.dataService.postUpdatePatientVSSC(data).subscribe( (res: any) => {
        if(res.status == 0){
          //
        } else {
          alert(res.msg);
        }
  
      });

    }
  }


  registrationCard(){
    //@ts-ignore
    var doc = new jsPDF('l', 'mm', [86, 54]);

    doc.setFontSize(9);
    doc.setFontType("bold");
    let y = 20;
    doc.setFontSize(10.5);
    doc.text(80, y, this.client.reg_no, 'right');

    doc.setFontSize(9);
    y += 3;
    doc.text(3, y, this.client.name);
    
    y+=9;

    doc.setFontSize(8);
    doc.setFontType("normal");
    doc.text(3, y, 'DOB: ' + this.client.dob_f + "    Sex: " + this.client.gender );
    
    y+=5;
    
    let address = doc.splitTextToSize(this.client.address, 60);
    doc.text(3, y, 'Address: ');

    
    address.forEach(line => {
      doc.text(15, y, line);  
      y +=3;
    });
    
    
    y+=2;
    doc.text(3, y, 'Phone: ' + this.client.phone);
    
    //doc.line(0,49, 86, 49);


    //output
    var string = doc.output('datauristring');
    var newWindow = window.open("", "PDF", 'dependent=yes,locationbar=no,scrollbars=no,menubar=no,resizable,screenX=50,screenY=50,width=850,height=800');
    newWindow.document.write(
    '<html><body><center>' +
    
    '</center><br>' +
    '<object width=100% height=100% type="application/pdf" data="'+string+'">' +
    '<embed type="application/pdf" src="'+string+'" id="embed_pdf"></embed>' +
    '</object></body></html>');
    newWindow.window.focus();

  }

  registrationCard_old(){
    //@ts-ignore
    var doc = new jsPDF('l', 'mm', [86, 54]);

    doc.setFontSize(8);

    let y = 17;
    doc.text(3, y, 'Patient Name: ' + this.client.name);
    y+=7;
    doc.text(3, y, 'Age: ' + this.client.ageY);
    doc.text(20, y, 'Sex: ' + this.client.gender);

    doc.text(80, y, 'OP No.: ' + this.client.reg_no, 'right');

    y+=7;
    
    let address = doc.splitTextToSize(this.client.address, 60);
    doc.text(3, y, 'Address: ');

    
    address.forEach(line => {
      doc.text(20, y, line);  
      y +=5;
    });
    
    
    y+=2;
    doc.text(3, y, 'Phone: ' + this.client.phone);
    
    //doc.line(0,49, 86, 49);


    //output
    var string = doc.output('datauristring');
    var newWindow = window.open("", "PDF", 'dependent=yes,locationbar=no,scrollbars=no,menubar=no,resizable,screenX=50,screenY=50,width=850,height=800');
    newWindow.document.write(
    '<html><body><center>' +
    
    '</center><br>' +
    '<object width=100% height=100% type="application/pdf" data="'+string+'">' +
    '<embed type="application/pdf" src="'+string+'" id="embed_pdf"></embed>' +
    '</object></body></html>');
    newWindow.window.focus();

  }


  merge(){

    if(this.ogPatientId != 0){
      let data = {
        og_id:this.ogPatientId,
        duplicate_id:this.id
      }

      this.merging = true;

      this.dataService.postPatientMerge(data).subscribe( (res:any) => {
        
        this.merging = false;

        if(res.status == 0) {

          alert(res.msg);

          $(".btn-close-modal").trigger("click");
          
          this.router.navigate(['/dashboard/client/'+this.ogPatientId]);
          
          
        } else {
          alert(res.msg);
        }

      });

    } else {
      alert("OG patient ID is missing")
    }

    
  }

}
