import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule,ReactiveFormsModule }from'@angular/forms';
import { MaterialModule } from '../material.module';

import { InfoComponent } from './info/info.component';
import { Routes, RouterModule } from '@angular/router';
import { SidebarBottomComponent } from './sidebar-bottom/sidebar-bottom.component';



const routes: Routes = [
  {'path':'info/:id', component:InfoComponent}
]

@NgModule({
  declarations: [InfoComponent, SidebarBottomComponent],
  imports: [
    CommonModule,
    FormsModule,
    //ReactiveFormsModule,
    MaterialModule,
    RouterModule.forChild(routes),
    
  ],
  exports:[
    InfoComponent,
    SidebarBottomComponent,
    RouterModule,
    
  ]
})
export class ProfileModule { }
