import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-patient-labresults',
  templateUrl: './patient-labresults.component.html',
  styleUrls: ['../patient-profile/patient-profile.component.scss', './patient-labresults.component.scss']
})
export class PatientLabresultsComponent implements OnInit {

  id:any = 0;

  data:any = {list:[]}

  constructor(private router:Router, private route: ActivatedRoute, private dataService: DataService) { }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    this.loadData();
  }

  loadData(){
    this.dataService.getClientLabResults(this.id).subscribe( (data: any[] )=> {
      this.data = data;
    });
  }

}
