import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-enquiry',
  templateUrl: './enquiry.component.html',
  styleUrls: ['./enquiry.component.scss']
})
export class EnquiryComponent implements OnInit {

  constructor(private dataService: DataService, private router:Router) { }

  data:any = {status:0, enquiries:[], count:0, consultants:[], branches:[], role:0};
  search="";
  limit=20;
  assignId = 0;
  role_id = localStorage.getItem('role_id');
  selectedBranch=0;
  branch:any = {id:0, name:""};

  ngOnInit() {

    if(localStorage.getItem("branch") !== null){
			this.branch = JSON.parse( localStorage.getItem("branch"));
			
		}

  	this.loadEnquiries();


    $( function() {
      (<any>$("#filter-date" )).datepicker({
          dateFormat: "dd-mm-yy",
          beforeShow: function() {
              setTimeout(function(){
                  $('.ui-datepicker').css('z-index', 99999999999999);
              }, 0);
          },
          onSelect: function (dateText, inst) {
          this.focus();
          }
      });
    });

  }

  filterBranch(branch){
    this.selectedBranch = branch;
    this.loadEnquiries();
  }

  loadEnquiries(){
  	//@ts-ignore
    let branch_id = (this.role_id == 1) ? this.selectedBranch : this.branch.id;
  	this.dataService.getEnquiries(this.limit, this.search, branch_id).subscribe( (data: any[] )=> {
  		this.data = data;

  	});

  }

  searchEnquiries(search){
    this.search = search;
    this.loadEnquiries();
  }

  loadMore() {
    this.limit +=20;
    this.loadEnquiries();
  }

  deleteEnquiry(id) {
    var cnf = confirm("Are you sure you want to delete this enquiry?");

    if(cnf) {
      var data = {"enquiry_id":id};
      this.dataService.postDeleteEnquiry(data).subscribe( (res:any) => {
        if(res.status == 0) {
            alert(res.msg);
            this.loadEnquiries();
            
            
          } else {
            alert(res.msg);
          }
      } );
    }

  }

  actionAssign(id){
    this.assignId = id;
    
  }

  assignEnquiry(data){
    data.enquiry = this.assignId;


    $('#btn-submit-assign').attr('disabled', 'true');
    $('#btn-submit-assign').text('Submitting');

    this.dataService.postEnquiryAssignment(data).subscribe( (res:any) => {
      if(res.status == 0) {
          alert(res.msg);
          $('#modalAssignment').modal('hide');
          $('#btn-submit-assign').removeAttr('disabled');
          $('#btn-submit-assign').text('Submit');
          this.loadEnquiries();
          
        } else {
          alert(res.msg);
        }
    } );


  }

  filterDate(date) {

    if(date !== "" && date != null){
      this.search = date;
      this.loadEnquiries();
      $("#filter-date").blur();
    }

     
  }

  registerEnquiry(id, name, phone, phone2, fields){

    $('#inputName').val(name);
    $('#inputPhone').val(phone);
    $('#inputPhone2').val(phone2);
    $('#inputEid').val(id);
    var arr = fields.split(", ");

    arr.forEach(function(item, index){
      $('.treatment-selector li:contains("'+item+'")').addClass('active');
    })

  }

  


}
