import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./newdashboard.scss','./calandar.scss', './home.component.scss']
})
export class HomeComponent implements OnInit {

	selected: Date | null; //Non-ISO for calendar;
	date:Date | null; //Don't know why;
	data:any = {date:"", upcoming_appointments:[], consultations:[], recent_appointments:[]};
	selectedDate = ''; //ISO string;
	incompleteRegistrations:any = [];
	reschedule:any = {};
	consultationTypes:any = [
		"",
		"1st Visit",
		"Procedure",
		"Online",
		"Review",
		
	  ];
  	constructor(private dataService: DataService) { }

	ngOnInit() {
		this.selectedDate = this.dataService.localData.homeDate; 

		if(this.selectedDate != "" && this.selectedDate != null && this.selectedDate.length > 5) {
			this.selected = new Date(this.selectedDate);
		}

		this.date = new Date()
		this.loadDashboard();
		this.loadIncomplete();

		$( function() {
			(<any>$("#schedule-date" )).datepicker({
		    	dateFormat: "yy-mm-dd",
		    	onSelect: function (dateText, inst) {
					this.focus();

			    }
			});

			
		});

	}

	timerOut(){
		var date1 = new Date();
		var date2 = new Date('2019-12-02 17:06:04');
		var diff = 0;
		//diff = date1 - date2;
		//return diff;
	}

	setToday(){
		this.selected=new Date();
		this.selectedDate = new Date().toISOString().split('T')[0];;
		this.loadDashboard();
	}

	loadDashboard(){
		this.dataService.getTherapistDashboard(this.selectedDate).subscribe( (data: any[] )=> {
			this.data = data;
			
		} )
	}

	loadIncomplete(){
		this.dataService.getIncompleteRegistrations().subscribe( (data: any[] )=> {
			this.incompleteRegistrations = data;
			
		} )
	}

	

	endSession(id) {
		var data = {"appointment_id":id};
		this.dataService.endSession(data).subscribe( (res: any) => {
			if(res.status == 0){
				this.loadDashboard();
			} else {
				alert(res.msg);
			}

		});
	}

	startSession(id) {
		var data  = {"appointment_id":id};
		this.dataService.startSession(data).subscribe( (res: any) => {
			if(res.status == 0){
				this.loadDashboard();
			} else {
				alert(res.msg);
			}

		});
	}

	markComplete(id) {
		var data  = {"appointment_id":id};
		this.dataService.postMarkComplete(data).subscribe( (res: any) => {
			if(res.status == 0){
				this.loadDashboard();
			} else {
				alert(res.msg);
			}

		});
	}

	markConsultation(id) {
		var data  = {"id":id};
		this.dataService.postMarkConsultation(data).subscribe( (res: any) => {
			if(res.status == 0){
				this.loadDashboard();
			} else {
				alert(res.msg);
			}

		});
	}

	undo(id, type) {
		var data = {"id":id, "type":type};
		this.dataService.postUndoCompleted(data).subscribe( (res: any) => {
			if(res.status == 0){
				this.loadDashboard();
			} else {
				alert(res.msg);
			}

		});
	}

	markAbsent(id){
		var data  = {"id":id};
		this.dataService.postMarkAbsent(data).subscribe( (res: any) => {
			if(res.status == 0){
				this.loadDashboard();
			} else {
				alert(res.msg);
			}

		});
	}

	undoAbsent(id) {
		console.log(id);
		var data  = {"id":id};
		this.dataService.postUndoAbsent(data).subscribe( (res: any) => {
			if(res.status == 0){
				this.loadDashboard();
			} else {
				alert(res.msg);
			}

		});
	}

	switchDate(date) {
		this.selectedDate = date;
		this.dataService.localData.dateDashboard = date;
		this.loadDashboard();
	}
	

	moveDate(mode){
		var newDate;
		var selected = (this.selectedDate.length > 5) ?   new Date(this.selectedDate) : new Date() ;
		if(mode == 1){
			newDate = new Date(new Date(selected).setDate(selected.getDate() + 1));
		} else {
			newDate = new Date(new Date(selected).setDate(selected.getDate() - 1));
		}

		this.selectedDate = newDate.toISOString().split('T')[0];
		this.dataService.localData.dateDashboard = this.selectedDate;
		this.loadDashboard();
	}

	confirmAppointmentStatus(appointment, attendance){

		let data = {id:appointment.appointment_id, attendance: attendance}

		this.dataService.postMarkAttendance(data).subscribe( (res: any) => {
			if(res.status == 0){
				//this.loadDashboard();
				appointment.attendance = attendance;
			} else {
				alert(res.msg);
			}

		});
	}

	cancelAppointment(appointment){

		var cnf = confirm("Do you really want to cancel this appointment?");

		if(cnf){
			this.dataService.cancelAppointment(0, appointment.appointment_id).subscribe( (res: any) => {
				if(res.status == 0){
					this.loadDashboard();
				} else {
					alert(res.msg);
				}
	
			});
		}
		
		

	}

	selectAppointmentReschedule(appointment){
		this.reschedule = appointment;

		//$('#rescheduleModal').modal('show');

	}

	startAppointment(appointment, mode=1){
		this.dataService.startTherapySession(appointment.appointment_id, mode).subscribe( (data:any = {status:0, msg:""} )=> {
			if(data.status == 0){
				appointment.is_active = mode;
				if(mode == 1){
					if(data.is_nabidh == 1){
						//Send nabidh request;
						this.dataService.sendNabidhA04(appointment.appointment_id).subscribe( (data:any )=> {
							console.log(data);
						} );
					}
					this.data.active_count++;
					this.data.upcoming_count--;
				} else {
					this.data.active_count--;
					this.data.upcoming_count++;
				}
			}
		} );
	}

	ignoreIncomplete(p){
		let data = {id:p.id};
		this.dataService.postIgnoreIncompleteRegistration(data).subscribe( (res: any) => {
			
			if(res.status == 0){
				this.loadIncomplete();
			} else {
				alert(res.msg);
			} 

		});
	}


	onCalendarSelect(date){
		var offset = date.getTimezoneOffset() * 60000;
		var isoDate = new Date(date.getTime() - offset ).toISOString().split('T')[0];
		this.selectedDate = isoDate;
		this.dataService.localData.homeDate = isoDate;
		//this.dataService.localData.dateDashboard = isoDate;
		this.loadDashboard();
		console.log(this.selected);
	}

	loadEdPatient(patient){
		this.dataService.getPatientProfile(patient.id).subscribe( (data:any )=> {
			this.dataService.edPatient = data;
			
		} )
	}

}
