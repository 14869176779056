import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../data.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {

  date:any =new Date();
  data:any = {status:0}
  payments:any = [];

  constructor(private dataService: DataService) { }

  ngOnInit() {

    this.loadData();
    this.loadPayments();

    $( function() {
			(<any>$("#schedule-date" )).datepicker({
		    	dateFormat: "dd-mm-yy",
		    	onSelect: function (dateText, inst) {
					this.focus();
          this.loadDate(dateText);

			    }
			});

			
		});
  }

  loadDate(date){

    if(date !== null){
    
      this.date = date;
    
    }

    
  }

  loadData(){
  	this.dataService.getOrgProfile().subscribe( (data: any )=> {
	  	console.log(data)	;
      if(data.status == 0){
          this.data = data;
          
        }
	  		
	  	} );
  }

  loadPayments(){
  	this.dataService.getOrgPayments().subscribe( (data: any )=> {
	  	
      if(data.status == 0){
          this.payments = data.payments;
          
        }
	  		
	  	} );
  }

}
