import { Component,  OnInit, ViewChild } from '@angular/core';
import { DataService } from '../../data.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-add-remarks',
  templateUrl: './add-remarks.component.html',
  styleUrls: ['../patient-profile/patient-profile.component.scss', './add-remarks.component.scss']
})
export class AddRemarksComponent implements OnInit {
  @ViewChild('acInput') acInput;
  id:any = 0;
  selectedTitle:any = "";
  selectedKey:any = "";
  selectedIndex:any = 0;
  sdId:any = 0;
  note:any = ""; // Update value for every keystroke
  noteHtml:any = ""; //Update only when API response arrives
  aiGenerationStatus:any = false;
  tags:any = [];
  tagSearchKeyWord:any = "";
  suggestions:any = [];
  maxWords:any = 120;
  tagInput:any = "";
  isNew:any = true;
  presets:any = {
    details:[],
    complaints:[],
    past_history:[],
    family_history:[],
    exam_findings:[],
    investigation:[],
    diagnosis:[],
    treatment:[],
    procedure_notes:[],
    procedure_history:[],
    remarks:[],
  };
  selectedPresetList:any = [];
  selectedPresetKey:any = "";
  selectedPresetTitle:any = "";

  data:any = [
    {key:"details", title:"Patient's Details", note:""},
    {key:"complaints", title:"Presenting Complaints", note:""},
    {key:"past_history", title:"Past History", note:""},
    {key:"family_history", title:"Family History", note:""},
    {key:"exam_findings", title:"Examination Findings", note:""},
    {key:"investigation", title:"Investigation", note:""},
    {key:"diagnosis", title:"Diagnosis", note:""},
    {key:"treatment", title:"Treatment", note:""},
    {key:"procedure_notes", title:"Procedure Notes", note:""},
    {key:"procedure_history", title:"Procedure History", note:""},
    {key:"remarks", title:"Remarks", note:""},
    
  ]

  metaData:any = {
    user:{id:0, name:""},
    logged_in_user:99999999,
    date:""
  }

  constructor(private router:Router, private route: ActivatedRoute, private dataService: DataService) {
    this.router.routeReuseStrategy.shouldReuseRoute = function() {
      return false;
    };
  }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    
    this.sdId = this.route.snapshot.paramMap.get('sdid');
    if(this.sdId != 0){
      this.isNew = false;
      this.loadSd();
    }

    this.loadPresets();

  }

  loadSd(){
    let context = this;
    this.dataService.getClientSd(this.sdId ).subscribe( (data:any = {status:0, data:[]} )=> {
      if(data.status == 0){
        this.data = data.data;
        this.metaData = {
          user:data.user,
          logged_in_user:data.logged_in_user,
          date:data.date
        }

      }
    });
  }

  loadPresets(){
    let context = this;
    this.dataService.getOPCardPresets().subscribe( (data:any )=> {
      if(data.status == 0){
        this.presets = data.list;

      }
    });
  }

  selectItem(item, key, index){
    this.selectedTitle = item;
    this.selectedKey = key;
    this.selectedIndex = index;
    this.note = this.data[index].note;
    this.tags = [];
    
  }

  submitNotes(data){
    data.patient_id = this.id;
    data.title = this.selectedTitle;
    data.sdId = this.sdId;
    data.key = this.selectedKey;
    data.note_contents = this.note;

    
    this.dataService.postClientSd(data).subscribe( (res:any) => {
      if(res.status == 0) {
        $('#modalRemarks').modal('hide');
        $('.btn-cancel-remarks').click();
        this.sdId = res.sdId;
        this.data[this.selectedIndex].note = res.note;
        //let elem = this.data.find( (e) => e.key = res.key );
        //elem.note = res.note;
        
      } else {
        alert(res.msg);
      }


    }); 

  }

  saveRemarks(){
    alert("Remarks saved");
  }

  generateNote(){
    

    if(this.tags.length > 0){
      let data = {
        tags:this.tags,
        patient_id:this.id,
        title:this.selectedTitle,
        maxWords:this.maxWords
      }

      this.aiGenerationStatus = true;
      this.dataService.postGenerateSdNote(data).subscribe( (res:any) => {
        this.aiGenerationStatus = false;
        if(res.status == 0) {
          this.note = res.body;
          this.noteHtml = this.note;
          //this.data[this.selectedIndex].note = res.note;
          //let elem = this.data.find( (e) => e.key = res.key );
          //elem.note = res.note;
          
        } else {
          alert(res.msg);
        }


      }); 

    } else {
      alert("Enter/pick at least one tag");
    }


    



  }

  /*updateNote(val){
    this.note = val.innerText;
    
    let lastElement = this.getLastElement(this.note);
    if(lastElement.length >= 5){
      this.dataService.getFormTextSuggestion(lastElement, this.selectedTitle).subscribe( (data:any )=> {
        
        if(data.status == 0){
          //this.noteHtml = val.innerText +  "<span style='color: #767676;'>"+data.suggestion+"</span>";
          this.insertTextAtCursor(val, data.suggestion);
        }
      });
    }
  } */
 
  getLastElement(inputString, delimiter = ",") {
    var arrayOfElements = inputString.split(delimiter);
    var lastElement = arrayOfElements[arrayOfElements.length - 1];
    return lastElement;
  }


  insertTextAtCursor(contentEditableElement, textToInsert) {
    var range, node;
    
    if (window.getSelection && window.getSelection().getRangeAt) {
        range = window.getSelection().getRangeAt(0);
        node = range.createContextualFragment(textToInsert);
        range.insertNode(node);
    //@ts-ignore
    } else if (document.selection && document.selection.createRange) {
        //@ts-ignore
        document.selection.createRange().pasteHTML(textToInsert);
    }

    this.note = contentEditableElement.innerText;

  }


  removeTagByIndex(index) {
    // Check if the index is valid
    if (index >= 0 && index < this.tags.length) {
      // Use splice to remove the element at the specified index
      this.tags.splice(index, 1);
    } else {
      console.error("Invalid index");
    }
  }
  
  /* Autocomplete */

  

  selectSuggestionEvent(event){
    
    if (!event || !event.match) {
      console.error("Invalid event object:", event);
      return;
    }
    //add to array
    this.tags.push(event.match);
    this.acInput.clear();
    this.acInput.focus();
    this.suggestions = [];
    
    
  }

  pushToTags(tag){
    this.suggestions = [""];
    //add to array
    this.tags.push(tag);
    this.tagInput = "";
    this.onChangeSuggestionSearch(" ");
    
    
  }

  
  onClearSuggestionSearch(event){
    //
  }
  
  

  onChangeSuggestionSearch(event){
    
    this.suggestions = [];
    let obj = {match:event};
    this.suggestions.push({match:event});
    if(event.endsWith(',')){
      this.pushToTags(this.removeTrailingComma(event));
    }
    

    this.dataService.getFormTextSuggestions(event, this.selectedTitle).subscribe( (data: any )=> {
      this.suggestions = data;
      this.suggestions.push({match:event})
      
    } );

    

  }

  
  

  endsWithComma(inputString) {
    // Using endsWith method to check if the string ends with a comma
    return inputString.endsWith(',');
  }

  removeTrailingComma(inputString) {
    // Use a regular expression to remove the trailing comma
    return inputString.replace(/,\s*$/, '');
  }

  applyPreset(title, key, index, value){
    if(this.data[index].note.length == 0) {
      this.data[index].note = value;
    } else {
      this.data[index].note += ", " + value;
    }
    
    this.selectItem(title, key, index);
    this.submitNotes({});
  }

  selectPresetList(list, key, title){
    this.selectedPresetKey = key;
    this.selectedPresetTitle = title;
    this.selectedPresetList = list;
    
  }

  editToggle(preset){
    
    this.selectedPresetList.forEach(element => {
      if(element.id != preset.id){
        element.editing = false;
      }
      
    });
    if(preset.hasOwnProperty('editing') && preset.editing == true){
      preset.editing = false;
    } else {
      preset.editing = true;
    }
  }

  updatePreset(preset){
    preset.editing = false;
    preset.item_key = this.selectedPresetKey;
    this.dataService.postUpdateOPCPreset(preset).subscribe( (res:any) => {
      
      if(res.status == 0) {
        preset.id = res.id;
      } else {
        alert(res.msg);
      }


    }); 
  }

  addNewPreset(){
    this.selectedPresetList.push({
      id:0,
      text:"",
      editing:true,
    })
  }


  deletePreset(preset){

    let cnf = confirm("Do you really want to delete this preset?");

    if(cnf){
      this.dataService.postDeleteOPCPreset(preset).subscribe( (res:any) => {

        if(res.status == 0) {
          this.selectedPresetList = this.selectedPresetList.filter(elem => elem.id !== preset.id);
          this.presets[this.selectedPresetKey] = this.selectedPresetList;
        } else {
          alert(res.msg);
        }
  
  
      }); 
    }

    
  }

}
