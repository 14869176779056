import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-patients-new',
  templateUrl: './patients-new.component.html',
  styleUrls: ['./patients-new.component.scss','../home/newdashboard.scss']
})
export class PatientsNewComponent implements OnInit {
  mode:any = "";
  limit:any = 30; 
  appointments:any = {date:"", upcoming_appointments:[], consultations:[], recent_appointments:[]};
  selectedDate = '';
  data:any = {count:0, search:"", patients:[]};
  search = "";
  department= 0;
  departments = [];
  branch:any = {id:0, name:""}; //assign value to view patients of another branch;
  incompleteRegistrations:any = [];
  branches:any = [];
  showAfter:any = 0;
  loading:any = false;
  role = localStorage.getItem('role_id');
  
  /* referred */
  refFrom:any = "";
  refDate:any = "";

  constructor(private dataService: DataService, private router:Router, private route: ActivatedRoute) { }

  ngOnInit() {

	if(this.dataService.localData.patientMode == ""){
		this.mode = this.route.snapshot.paramMap.get('mode');
		this.dataService.localData.patientMode = this.mode;

	} else {
		this.mode = this.dataService.localData.patientMode;
	}

	
	
	if(this.mode == 'branch'){
		this.loadData();
		this.loadIncomplete();
		this.loadBranchPatients();
	} else {
		this.loadReferred();
	}

    
  }

  loadData(){
    this.loadAppointments();
    this.loadPatients();
  }

  loadReferred() {
	this.dataService.getPatientsReferred(this.limit, this.search, 0).subscribe( (data: any[] )=> {
			this.data = data;
			
	} );
  }

  	filterBranch(id){
		console.log(id);
		this.refFrom = id;
		this.loadReferred();
	}

  loadIncomplete(){
		this.dataService.getIncompleteRegistrations().subscribe( (data: any[] )=> {
			this.incompleteRegistrations = data;
			
		} )
	}

	loadBranchPatients(){
		this.dataService.getBranchPatients().subscribe( (data: any[] )=> {
			this.branches = data;
			
		} )
	}

  loadAppointments(){
    this.dataService.getTherapistDashboard(this.selectedDate, 1, 0).subscribe( (data: any[] )=> {
			this.appointments = data;
			
		} )
  }

  loadPatients() {
		this.loading = true;

		if(this.dataService.localData.roleId == '4'){
			this.dataService.getDocPatients(this.limit, this.search, this.department, this.branch.id, this.showAfter).subscribe( (data: any[] )=> {
				this.data = data;
				this.loading = false;
				
			} );
		} else {
			this.dataService.getPatientsV2(this.limit, this.search, this.department, this.branch.id, this.showAfter).subscribe( (data: any[] )=> {
				this.data = data;
				this.loading = false;
				
			} );
		}

		
	}



  confirmAppointmentStatus(appointment, attendance){

		let data = {id:appointment.appointment_id, attendance: attendance}

		this.dataService.postMarkAttendance(data).subscribe( (res: any) => {
			if(res.status == 0){
				//this.loadDashboard();
				appointment.attendance = attendance;
			} else {
				alert(res.msg);
			}

		});
	}

	cancelAppointment(appointment){

		var cnf = confirm("Do you really want to cancel this appointment?");

		if(cnf){
			this.dataService.cancelAppointment(0, appointment.appointment_id).subscribe( (res: any) => {
				if(res.status == 0){
					this.loadAppointments();
				} else {
					alert(res.msg);
				}
	
			});
		}
		
		

	}


  submitSearch(data){
    this.search = data.search;
	this.showAfter = 0;
	if(this.mode == 'branch'){
		this.loadPatients();
	} else {
		this.loadReferred();
	}

    
  }

  loadEdPatient(patient){
	this.dataService.getPatientProfile(patient.id).subscribe( (data:any )=> {
		this.dataService.edPatient = data;
		
	} )
  }

  loadMore(){
	  this.showAfter = this.data.patients.length;
	  this.loading = true;

	if(this.dataService.localData.roleId == '4'){
		this.dataService.getDocPatients(this.limit, this.search, this.department, this.branch.id, this.showAfter).subscribe( (data: any )=> {
			//this.data = data;
			this.loading = false;
			data.patients.forEach(element => {
				this.data.patients.push(element);
			});
		} );
	} else {
		this.dataService.getPatientsV2(this.limit, this.search, this.department, this.branch.id, this.showAfter).subscribe( (data: any )=> {
			this.data = data;
			this.loading = false;
			data.patients.forEach(element => {
				this.data.patients.push(element);
			});
		} );
	}

		
  }

  loadMoreReferred(){
	this.showAfter = this.data.patients.length;
	this.loading = true;

	this.dataService.getPatientsReferred(this.limit, this.search, this.showAfter).subscribe( (data: any )=> {
		//this.data = data;
		this.loading = false;
		data.patients.forEach(element => {
			this.data.patients.push(element);
		});
	} );

	  
}

  setMode(mode){
	  if(this.mode != mode){
		this.mode = mode;
		this.dataService.localData.patientMode = this.mode;
		if(this.mode == 'branch'){
			this.loadData();
			this.loadIncomplete();
			this.loadBranchPatients();
		} else {
			
			this.loadReferred();
		}
	  }

	  
  }

  deletePatient(patient, index){
	let cnf = confirm("Do you really want to delete " + patient.name + "? You can't undo this later.");

	if(cnf){
		let data = {id:patient.id}
		this.dataService.postDeletePatient(data).subscribe( (res: any) => {
			if(res.status == 0){
				this.data.patients.splice(index, 1);
				
			} else {
				alert(res.msg);
			}

		});
	}

  }

}
